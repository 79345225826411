import Header from "components/Header";
import AboutUs from "components/AboutUs";
import Footer from "components/Footer";
import FeaturedProperties from "components/FeaturedProperties";
import "./Home.css";

export default function Home() {
  return (
    <>
      <Header />
      <AboutUs />

      <FeaturedProperties />

      <section class="Company_goal">
        <div class="Company_goal_text">
          <div>
            <h3>
              Generating more value <br />
              at every level and building a better world for everyone
            </h3>
          </div>
          <div>
            <p>
              Start working with Kazfield that can provide everything you need
              to generate awareness, drive traffic, connect.
            </p>
          </div>
        </div>
      </section>

      <section class="Management_Team">
        <div class="Management_Team_Text">
          <div>
            <h3>Our Team</h3>
          </div>
        </div>
        <div class="Management_Team_Members">
          <div class="Management_Team_photos">
            <div>
              <img src="./image/04.jpeg" alt="Kazfield" />
            </div>
            <div>
              <a href=""> Kazeem Yekinni </a>
            </div>
            <div>
              <p>CEO</p>
            </div>
          </div>

          <div class="Management_Team_photos">
            <div>
              <img src="./image/05.jpeg" alt="Kazfield" />
            </div>
            <div>
              <a href=""> Tomiwa Ogunremi </a>
            </div>
            <div>
              <p>Project Manager</p>
            </div>
          </div>
          <div class="Management_Team_photos">
            <div>
              <img src="./image/06.jpeg" alt="Kazfield" />
            </div>
            <div>
              <a href=""> I.T Specilist </a>
            </div>
            <div>
              <p>Adekanmi Adedara Adeyemi</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
