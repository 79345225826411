import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <section class="footer_Section">
      <div class="footer_Section_wrapper">
        <div class="footer_content">
          <div class="Logo_section">
            <div class="logo_img_div">
              <img src="./image/logo-light.png" alt="" />
            </div>
            <div>
              <p>
                Start working with Kazfield that can provide everything you need
                to generate awareness, drive traffic, connect.
              </p>

              <li
                style={{
                  color: "#d1d5db",
                  listStyleType: "none",
                  marginTop: "15px",
                }}
              >
                <a
                  href="https://wa.me/+2348164371968"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "15px" }}
                >
                  <i class="fa fa-whatsapp" style={{ color: "white" }}></i>
                </a>

                <a
                  href="https://www.instagram.com/invites/contact/?i=1dyhbw0o8c85y&utm_content=nmgr85z"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fab fa-instagram" style={{ color: "white" }}></i>
                </a>
              </li>
            </div>
            <div class=" icons"></div>
          </div>
          <div class="company_details">
            <h5>Company</h5>
            <div class="list_items">
              <ul>
                <li>
                  <Link style={{ color: "#d1d5db" }} to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "#d1d5db" }} to="/properties">
                    Properties
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "#d1d5db" }} to="/contact">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "#d1d5db" }} to="/services">
                    Services
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "#d1d5db" }} to="/portal">
                    Realtors
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="Useful_Links">
            <h5> Useful Links</h5>
            <div class="list_items">
              <ul>
                <li> Terms of Services</li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>
          <div class="NewsLetter">
            <h5> Contact </h5>

            <div class="Newsletter_content">
              <ul>
                <li>
                  <a href="tel://+2348164371968"> +234 816 437 1968 </a>
                </li>
                <li style={{ color: "#d1d5db" }}>
                  <a href="tel://+2348164371968" style={{ color: "#d1d5db" }}>
                    {" "}
                    +234 816 437 1968{" "}
                  </a>
                </li>
                <li style={{ color: "#d1d5db" }}>
                  <a
                    href="tel://+234 802 411 2940"
                    style={{ color: "#d1d5db" }}
                  >
                    {" "}
                    +234 802 411 2940{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="Footer_Sponsors_Section">
        <div class="Footer_Sponsors_content">
          <p>&copy; 2023 Kazfield Integrated Services Ltd</p>
          <div class="sponsors_logo"></div>
        </div>
      </div>
    </section>
  );
}
