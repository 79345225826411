import Nav from "components/Nav";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Header() {
  return (
    <>
      <section class="header">
        <Nav />

        <div class="header_texts">
          <div class="header_content">
            <div class="first_paragraph">
              <div class="Dream_home">
                <h4>Your journey to building your</h4>
                <h4>dream home starts here</h4>
              </div>
              <div class="second_paragraph">
                <p>
                  Kazfield Integrated Services Ltd is an estate development and
                  brokerage firm. We specialize in estate development.
                </p>
              </div>
              <div class="contact_btn">
                <Link to="/contact">
                  <button>Contact Us</button>
                </Link>
              </div>
            </div>
            <div class="video_btn" style={{ display: "none" }}>
              <div class="video_btn_icon">1</div>
            </div>
          </div>
        </div>
      </section>

      <section class="project_plan_section">
        <div class="project_plan_wrapper ">
          <div class="white_section">
            <div class="project_plan">
              <div class=" plan_top_content">
                <div class="icon_section">
                  <i
                    class="fa fa-globe"
                    aria-hidden="true"
                    style={{ color: "white" }}
                  ></i>
                </div>
                <div class="project_planning">
                  <div>
                    <h4> Property acquisition </h4>
                  </div>
                  <div>
                    <p>
                      {" "}
                      With Kazfield we give the best landed and real estate
                      properties{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div class="plan_bottom_content">
                <div class="icon_section">
                  <i
                    class="fa fa-building"
                    aria-hidden="true"
                    style={{ color: "white" }}
                  ></i>
                </div>
                <div class="project_planning">
                  <div>
                    <h4> Land banking </h4>
                  </div>
                  <div>
                    <p>
                      {" "}
                      Buying lands for land banking has been our specialization{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="project_plan">
              <div class="plan_top_content">
                <div class="icon_section">
                  <i
                    class="fa fa-area-chart"
                    aria-hidden="true"
                    style={{ color: "white" }}
                  ></i>
                </div>
                <div class="project_planning">
                  <div>
                    <h4> Property investment </h4>
                  </div>
                  <div>
                    <p>
                      {" "}
                      Land don't get devauled, land never get bad, property
                      investment is a key.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div class=" plan_bottom_content">
                <div class="icon_section">
                  <i
                    class="fa fa-balance-scale"
                    aria-hidden="true"
                    style={{ color: "white" }}
                  ></i>
                </div>
                <div class="project_planning">
                  <div>
                    <h4> Property sales </h4>
                  </div>
                  <div>
                    <p>
                      {" "}
                      Sales of properties is quiet easy with Kazfield, and we
                      give the cheaper sales ever.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Experience_section">
            <div class="Experience_content">
              <div class="Experience_top_content">
                {/* <span>Our Proud</span> */}
              </div>
              <div class="Experience_mid_content">
                {/* <h4> 15 years of undefeated success </h4> */}
              </div>
              <div class="Experience_bottom_content">
                <p>
                  Start working with Kazfield that can provide everything you
                  need to generate awareness, drive traffic, connect.
                </p>
              </div>
              <div class="About_us_btn">
                <Link to="/about">
                  <button>About Us</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
