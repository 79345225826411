import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <section class="About_company_section">
      <div class="about_company_section_wrapper">
        <div class="About_company_images">
          <img src="./image/ab01.jpg" alt="Kazfield Integrated Services" />
        </div>

        <div class="construction_worker_image">
          <img src="./image/ab02.jpg" alt="Kazfield Integrated Services" />
          <div class="About_company_Video_btn">
            <button>
              <i
                class="fa fa-briefcase"
                aria-hidden="true"
                style={{ color: "white" }}
              ></i>
            </button>
          </div>
        </div>
      </div>

      <div class="About_company_container">
        <div class="About_company_wrapper">
          <div class="About_company_texts">
            <div class="About_company_read_more_btn">
              <button>About Kazfield</button>
            </div>
            <div>
              <h4>
                Leading Real Estate
                <br /> Development Company
              </h4>
            </div>
            <div>
              <p>
                Kazfield Integrated Services Ltd is an estate development and
                brokerage firm. We specialize in estate development. Our primary
                focus is assisting investors in securing genuine landed
                properties without any hassle. We have successfully helped
                numerous clients both locally and internationally.
              </p>
            </div>
            <div class="About_company_read_more_btn">
              <Link to="/services">
                <button>Read More</button>
              </Link>
            </div>
            {/* <hr /> */}
            {/* <div class="projects_completed">
              <div class="project_completed">
                <div>
                  <p>548+</p>
                </div>
                <div>
                  <h5>project completed</h5>
                </div>
              </div>
              <div class="years_of_exprience">
                <div>
                  <p>15+</p>
                </div>
                <div>
                  <h5>Years of exprience</h5>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
